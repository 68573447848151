import moment from "moment"

const initialState = {
  isLoading: false,
  quotations: [],
  totalQuotations: 0,
  totalPages: 0,
  newQuotation: {
    id: "",
    quotationProducts: [],
    senderAddress: "184, Buthgamuwa Road",
    senderCity: "Rajagiriya, Sri Lanka",
    senderTel: "0112 805 005 / 0761 399 060 / 0777 275 626 / 0777 275 628",
    date: moment().format("YYYY-MM-DD"),
    quotationNumber: "",
    paymentMethod: "Cash",
    salesManager: "",
    paymentTerms: "On Delivery",
    deliverTo: "",
    customer: {},
    transport: "",
  },
}

const QuotationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_QUOTATION_LOADING":
      return {
        ...state,
        isLoading: action.data,
      }
    case `SET_QUOTATIONS`:
      return {
        ...state,
        quotations: action.data,
      }

    case `SET_TOTAL_QUOTATIONS`:
      return {
        ...state,
        totalQuotations: action.data,
      }

    case `SET_TOTAL_PAGES`:
      return {
        ...state,
        totalPages: action.data,
      }
    case `SET_QUOTATION_PRODUCTS`:
      return {
        ...state,
        newQuotation: { ...state.newQuotation, quotationProducts: action.data },
      }
    case `SET_QUOTE_DATE`:
      return {
        ...state,
        newQuotation: { ...state.newQuotation, date: action.data },
      }
    case `SET_QUOTATION_NUMBER`:
      return {
        ...state,
        newQuotation: { ...state.newQuotation, quotationNumber: action.data },
      }
    case `SET_QUOTE_PAYMENT_METHOD`:
      return {
        ...state,
        newQuotation: { ...state.newQuotation, paymentMethod: action.data },
      }
    case `SET_QUOTE_SALES_MANAGER`:
      return {
        ...state,
        newQuotation: { ...state.newQuotation, salesManager: action.data },
      }
    case `SET_QUOTE_PAYMENT_TERMS`:
      return {
        ...state,
        newQuotation: { ...state.newQuotation, paymentTerms: action.data },
      }
    case `SET_QUOTE_DELIVER_TO`:
      return {
        ...state,
        newQuotation: { ...state.newQuotation, deliverTo: action.data },
      }
    case `SET_QUOTE_CUSTOMER`:
      return {
        ...state,
        newQuotation: { ...state.newQuotation, customer: action.data },
      }
    case `SET_QUOTE_TRANSPORT`:
      return {
        ...state,
        newQuotation: { ...state.newQuotation, transport: action.data },
      }
    case `SET_QUOTE_ID`:
      return {
        ...state,
        newQuotation: { ...state.newQuotation, id: action.data },
      }
    case `CLEAR_NEW_QUOTATION`:
      return {
        ...state,
        newQuotation: { ...initialState.newQuotation },
      }

    default:
      return state
  }
}

export default QuotationReducer
