import moment from "moment"

const initialState = {
  invoiceProducts: [],
  senderAddress: "184, Buthgamuwa Road",
  senderCity: "Rajagiriya, Sri Lanka",
  senderTel: "0112 805 005 / 0761 399 060 / 0777 275 626 / 0777 275 628",
  date: moment().format("YYYY-MM-DD"),
  invoiceNumber: "",
  paymentMethod: "Cash",
  salesManager: "",
  paymentTerms: "On Delivery",
  dueDate: moment().add(7, "d").format("YYYY-MM-DD"),
  deliverTo: "",
  customerCode: "",
  customer: {},
  cancelled: false,
  transport: "",
  quotationNumber: "",
}

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case `SAVE_INVOICE`:
      return {
        ...state,
        invoiceNumber: action.data,
      }
    case `SET_INVOICE_PRODUCTS`:
      return {
        ...state,
        invoiceProducts: action.data,
      }
    case `ADD_PRODUCT`:
      return {
        ...state,
        invoiceProducts: action.data,
      }
    case `DELETE_PRODUCT`:
      return {
        ...state,
        invoiceProducts: action.data,
      }
    case `SET_DATE`:
      return {
        ...state,
        date: action.data,
      }
    case `SET_INVOICE_NUMBER`:
      return {
        ...state,
        invoiceNumber: action.data,
      }
    case `SET_PAYMENT_METHOD`:
      return {
        ...state,
        paymentMethod: action.data,
      }
    case `SET_TRANSPORT`:
      return {
        ...state,
        transport: action.data,
      }
    case `SET_SALES_MANAGER`:
      return {
        ...state,
        salesManager: action.data,
      }
    case `SET_PAYMENT_TERMS`:
      return {
        ...state,
        paymentTerms: action.data,
      }
    case `SET_DUE_DATE`:
      return {
        ...state,
        dueDate: action.data,
      }
    case `SET_DELIVER_TO`:
      return {
        ...state,
        deliverTo: action.data,
      }
    case `SET_CUSTOMER_CODE`:
      return {
        ...state,
        customerCode: action.data,
      }
    case `SET_CUSTOMER`:
      return {
        ...state,
        customer: action.data,
      }
    case `SET_INVOICE_QUOTATION_NUMBER`:
      return {
        ...state,
        quotationNumber: action.data,
      }
    case `CLEAR_INVOICE`:
      return {
        ...initialState,
      }

    default:
      return state
  }
}

export default invoiceReducer
