import moment from "moment"

const initialState = {
  paymentReceipts: [],
  isLoading: false,
  newPaymentReceipt: {
    senderAddress: "184, Buthgamuwa Road",
    senderCity: "Rajagiriya, Sri Lanka",
    senderTel: "0112 805 005 / 0761 399 060 / 0777 275 626 / 0777 275 628",
    date: moment().format("YYYY-MM-DD"),
    paymentReceiptNumber: "",
    paymentMethod: "Cash",
    amount: "",
    bank: "",
    remarks: "",
    chequeNumber: "",
    bankRef: "",
    chequeDate: moment().format("YYYY-MM-DD"),
    customer: {},
    invoiceNumbers: [],
    quotationNumbers: [],
  },
}

const PaymentReceiptReducer = (state = initialState, action) => {
  switch (action.type) {
    case `SET_PAYMENT_RECEIPTS`:
      return {
        ...state,
        paymentReceipts: action.data,
      }
    case "SET_PAYMENT_RECEIPTS_LOADING":
      return {
        ...state,
        isLoading: action.data,
      }
    case `SET_PAYMENT_RECEIPT_DATE`:
      return {
        ...state,
        newPaymentReceipt: { ...state.newPaymentReceipt, date: action.data },
      }
    case `SET_PAYMENT_RECEIPT_NUMBER`:
      return {
        ...state,
        newPaymentReceipt: {
          ...state.newPaymentReceipt,
          paymentReceiptNumber: action.data,
        },
      }
    case `SET_PAYMENT_RECEIPT_PAYMENT_METHOD`:
      return {
        ...state,
        newPaymentReceipt: {
          ...state.newPaymentReceipt,
          paymentMethod: action.data,
        },
      }
    case `SET_PAYMENT_RECEIPT_AMOUNT`:
      return {
        ...state,
        newPaymentReceipt: { ...state.newPaymentReceipt, amount: action.data },
      }
    case `SET_PAYMENT_RECEIPT_BANK`:
      return {
        ...state,
        newPaymentReceipt: { ...state.newPaymentReceipt, bank: action.data },
      }

    case `SET_PAYMENT_RECEIPT_REMARKS`:
      return {
        ...state,
        newPaymentReceipt: {
          ...state.newPaymentReceipt,
          remarks: action.data,
        },
      }

    case `SET_PAYMENT_RECEIPT_CHEQUE_NUMBER`:
      return {
        ...state,
        newPaymentReceipt: {
          ...state.newPaymentReceipt,
          chequeNumber: action.data,
        },
      }
    case `SET_PAYMENT_RECEIPT_CHEQUE_DATE`:
      return {
        ...state,
        newPaymentReceipt: {
          ...state.newPaymentReceipt,
          chequeDate: action.data,
        },
      }
    case `SET_PAYMENT_RECEIPT_BANK_REF`:
      return {
        ...state,
        newPaymentReceipt: { ...state.newPaymentReceipt, bankRef: action.data },
      }
    case `SET_PAYMENT_RECEIPT_CUSTOMER`:
      return {
        ...state,
        newPaymentReceipt: {
          ...state.newPaymentReceipt,
          customer: action.data,
        },
      }
    case `SET_PAYMENT_RECEIPT_INVOICE_NUMBERS`:
      return {
        ...state,
        newPaymentReceipt: {
          ...state.newPaymentReceipt,
          invoiceNumbers: action.data,
        },
      }

    case `SET_PAYMENT_RECEIPT_QUOTATION_NUMBERS`:
      return {
        ...state,
        newPaymentReceipt: {
          ...state.newPaymentReceipt,
          quotationNumbers: action.data,
        },
      }

    case `CLEAR_NEW_PAYMENT_RECEIPT`:
      return {
        ...state,
        newPaymentReceipt: { ...initialState.newPaymentReceipt },
      }
    default:
      return state
  }
}

export default PaymentReceiptReducer
