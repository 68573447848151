import moment from "moment"

const initialState = {
  creditNotes: [],
  totalCreditNotes: 0,
  isLoading: false,
  totalPages: 0,
  cancelled: false,
  newCreditNote: {
    invoice: [],
    creditNoteNumber: "",
    invoiceNumber: "",
    customerCode: "",
    creditNoteProducts: [],
    senderAddress: "184, Buthgamuwa Road",
    senderCity: "Rajagiriya, Sri Lanka",
    senderTel: "0112 805 005 / 0761 399 060 / 0777 275 626 / 0777 275 628",
    date: moment().format("YYYY-MM-DD"),
  },
}

const CreditNoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CREDIT_NOTE_LOADING":
      return {
        ...state,
        isLoading: action.data,
      }
    case "SET_CREDIT_NOTES":
      return {
        ...state,
        creditNotes: action.data,
      }
    case "SET_TOTAL_CREDIT_NOTES":
      return {
        ...state,
        totalCreditNotes: action.data,
      }
    case "SET_TOTAL_PAGES":
      return {
        ...state,
        totalPages: action.data,
      }
    case "SET_CREDIT_NOTE_PRODUCTS":
      return {
        ...state,
        newCreditNote: {
          ...state.newCreditNote,
          creditNoteProducts: action.data,
        },
      }

    case "SET_UPDATED_CREDIT_NOTE_PRODUCTS":
      return {
        ...state,
        newCreditNote: {
          ...state.newCreditNote,
          updatedCreditNoteProducts: action.data,
        },
      }

    case "ADD_CREDIT_NOTE_PRODUCTS":
      return {
        ...state,
        newCreditNote: {
          ...state.newCreditNote,
          creditNoteProducts: action.data,
        },
      }

    case "SET_CREDIT_NOTE_DATE":
      return {
        ...state,
        newCreditNote: { ...state.newCreditNote, date: action.data },
      }
    case "SET_CREDIT_NOTE_NUMBER":
      return {
        ...state,
        newCreditNote: {
          ...state.newCreditNote,
          creditNoteNumber: action.data,
        },
      }

    case "SET_CREDIT_NOTE_INVOICE":
      return {
        ...state,
        invoice: action.data,
      }

    case "SET_CREDIT_NOTE_INVOICE_NUMBER":
      return {
        ...state,
        newCreditNote: { ...state.newCreditNote, invoiceNumber: action.data },
      }

    case "SET_CREDIT_NOTE_CUSTOMER":
      return {
        ...state,
        newCreditNote: { ...state.newCreditNote, customer: action.data },
      }

    case "SET_CREDIT_NOTE_ID":
      return {
        ...state,
        newCreditNote: { ...state.newCreditNote, id: action.data },
      }
    case "CLEAR_NEW_CREDIT_NOTE":
      return {
        ...state,
        newCreditNote: { ...initialState.newCreditNote },
      }

    case "CANCEL_CREDIT_NOTE":
      return {
        ...state,
        cancelled: action.data,
      }

    default:
      return state
  }
}

export default CreditNoteReducer
